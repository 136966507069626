const key = "selected-lang";
const flashKey = "selected-flash-lang";

export default function () {
  return localStorage.getItem(key) || "all";
}
export function getSelectedFlashLang() {
  return localStorage.getItem(flashKey) || "en";
}

export function setSelectedLang(lang) {
  localStorage.setItem(key, lang);
}
export function setSelectedFlashLang(lang) {
  localStorage.setItem(flashKey, lang);
}
